import React, { useState, useEffect } from 'react';

const Cursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isPointer, setIsPointer] = useState(false);

  useEffect(() => {
    // Update cursor position
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    // Update cursor state based on tag
    const handleMouseOver = (e) => {
      const tag = e.target.tagName.toLowerCase();
      if (['a', 'input', 'button', 'select', 'textarea'].includes(tag)) {
        setIsPointer(true);
      } else {
        setIsPointer(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseover', handleMouseOver);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  return (
    <div
      className={`cursor ${isPointer ? 'cursor-pointer' : ''}`}
      style={{ left: cursorPosition.x, top: cursorPosition.y }}
    />
  );
};

export default Cursor;
