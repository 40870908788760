import React from 'react';
import { useParams } from 'react-router-dom';
import TeamData from '../data/pages/Team.json'; // Assuming the JSON data is stored in this location

function Team() {
    const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present
    const teamData = TeamData[lang] || TeamData['en']; // Fallback to English if `lang` is not available
    const teamMembers = teamData["members"];

    return (
        <div lang={lang} id="team" data-menu="team">
            <section className='content-spacing'>
                <div>
                    <h1>
                        {teamData.title}
                    </h1>
                    <p>
                        {teamData.text}
                    </p>
                </div>
            </section>
            

            <div className="team-flex">
                <div className="team-container">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="team-member">
                            <div>
                                <h1>{member.name}</h1>
                                <p>{member.position}</p>
                            </div>
                            <img
                                src={`/media/team/${member.name}_1000w_gradient.jpg`}
                                alt={member.name}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Team;
