import React from 'react';
import { useParams } from 'react-router-dom';
import importMedia from '../importMedia';
import ResponsiveImage from '../components/ResponsiveImage';
import AboutData from '../data/pages/About.json';
import ParticleBackground from '../components/ParticleBackground';

function About() {
    const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present
    const text = AboutData[lang] || AboutData['en']; // Fallback to English if `lang` is not available

    return (
        <div lang={lang} id='about' className='zigzag2big-desktop'>
            <ParticleBackground />
            <section className='content-spacing'>
                <div>
                    <h1>{text["immersive_technologies_header"]}</h1>
                    {text["immersive_technologies"].map((item, index) => (
                        <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                </div>
                <div>
                    <ResponsiveImage image={importMedia(`/media/pages/about/Processed/Jacoub Headset`)[0]} sizes='(min-width: 780px) calc(40vw - 128px), calc(100vw - 64px)' />
                </div>
            </section>
            <section className='content-spacing'>
                <div>
                    <h1>{text["multidiciplinary_team_header"]}</h1>
                    {text["multidiciplinary_team"].map((item, index) => (
                        <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                </div>
                <div>
                    <ResponsiveImage image={importMedia(`/media/pages/about/Processed/Pierre Soldering`)[0]} sizes='(min-width: 780px) calc(40vw - 128px), calc(100vw - 64px)' />
                </div>
            </section>
            <section className='content-spacing'>
                <div>
                    <h1>{text["scope_of_work_header"]}</h1>
                    {text["scope_of_work"].map((item, index) => (
                        <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                </div>
                <div>
                <ResponsiveImage image={importMedia(`/media/pages/about/Processed/Eye Screen`)[0]} sizes='(min-width: 780px) calc(40vw - 128px), calc(100vw - 64px)' />
                </div>
            </section>
        </div>
    );
}

export default About;
