import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ResponsiveImage from '../components/ResponsiveImage';
import ProjectsPageData from '../data/pages/Projects.json';
import importMedia from '../importMedia';

// Function to dynamically import JSON files
const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
};

// Import all project JSON files
const projectData = importAll(require.context('../data/projects', false, /\.json$/));

function Projects() {
    const { lang = 'en' } = useParams();
    const text = ProjectsPageData[lang] || ProjectsPageData['en']; // Fallback to English if `lang` is not available
    const [projects, setProjects] = useState([]);
    const [searchProject, setSearchProject] = useState('');
    const [projectType, setProjectType] = useState('');
    const [projectService, setProjectService] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [types, setTypes] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        // Convert projectData object to an array of projects
        const projectArray = Object.keys(projectData).map(key => ({
            id: key.replace('.json', ''),
            ...projectData[key][lang] || projectData[key]['en'] // Fallback to English if `lang` is not available
        }));

        // Extract unique types for the select dropdown
        const uniqueTypes = [...new Set(projectArray.flatMap(project => project.format || []))];
        const uniqueServices = [...new Set(projectArray.flatMap(project => project.services || []))];
        setTypes(uniqueTypes);
        setServices(uniqueServices)
        setProjects(projectArray);
    }, [lang]);

    useEffect(() => {
        // Filter projects based on search term and selected type
        const filtered = projects.filter(project => {
            const matchesSearch = project.title.toLowerCase().includes(searchProject.toLowerCase());
            const matchesType = projectType === '' || project.format.includes(projectType);
            const matchesService = projectService === '' || project.services.includes(projectService);
            return matchesSearch && matchesType && matchesService;
        });

        setFilteredProjects(filtered);
    }, [searchProject, projectType, projects, projectService]);

    const handleSearchChange = (e) => { 
        setSearchProject(e.target.value);
    };

    const handleTypeChange = (e) => { 
        setProjectType(e.target.value);
    };

    const handleServiceChange = (e) => { 
        setProjectService(e.target.value);
    };

    return (
        <div lang={lang} id='projects'>
            <section className='projects-filter'>
                    <div>
                        <h1>{text.title}</h1>
                    </div>
                    <div>
                        <input
                            type="text"
                            value={searchProject}
                            onChange={handleSearchChange}
                            placeholder={text["type_to_search"]}
                        />

                        <select onChange={handleTypeChange} value={projectType}>
                            <option value="">{text["all_types"]}</option>
                            {types.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </select>

                        <select onChange={handleServiceChange} value={projectService}>
                            <option value="">{text["all_services"]}</option>
                            {services.map((service, index) => (
                                <option key={index} value={service}>{service}</option>
                            ))}
                        </select>
                    </div>
            </section>
            <section className='projects-grid'>
                {filteredProjects.map(project => (
                    <a href={`/${lang}/projects/${project.id}`} key={project.id}>
                        <div>
                            <ResponsiveImage image={importMedia(`/media/projects/${project.id}/Processed/Carousel`)[0]} sizes='(min-width: 780px) calc(30vw - 80px), calc(100vw - 64px)' />
                        </div>
                        <div>
                            <h3>{project.title}</h3>
                            <p>{project.format.join(', ')}</p>
                        </div>
                    </a>
                ))}
            </section>
        </div>
    );
}

export default Projects;
