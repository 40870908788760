import React from 'react';
import { useParams } from 'react-router-dom';
import importMedia from '../importMedia';
import ResponsiveImage from '../components/ResponsiveImage';
import DistributionData from '../data/pages/Distribution.json';


function Distribution() {
    const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present
    const text = DistributionData[lang] || DistributionData['en']; // Fallback to English if `lang` is not available

    return (
        <div lang={lang} id='distribution' className='zigzag2big-desktop'>
            <section className='content-spacing'>
                <div>
                    <h1>{text.touring_header}</h1>
                    <p>{text.touring}</p>
                </div>
                <div>
                    <ResponsiveImage image={importMedia(`/media/pages/distribution/Processed/Close`)[0]} sizes='(min-width: 780px) calc(40vw - 128px), calc(100vw - 64px)' />
                </div>
            </section>
            <section className='content-spacing'>
                <div>
                    <h1>{text.distribution_header}</h1>
                    <p>{text.distribution}</p>
                </div>
                <div>
                    <ResponsiveImage image={importMedia(`/media/pages/distribution/Processed/Far`)[0]} sizes='(min-width: 780px) calc(40vw - 128px), calc(100vw - 64px)' />
                </div>
            </section>
            
        </div>
    );
}

export default Distribution;