import React from 'react';
import { useParams } from 'react-router-dom';

import ContactData from '../data/pages/Contact.json';
import FooterData from '../data/components/Footer.json';

function Contact() {
    const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present
    const contactData = ContactData[lang] || ContactData['en']; // Fallback to English if `lang` is not available
    const footerData = FooterData[lang] || FooterData['en']; // Fallback to English if `lang` is not available
    
    return (
        <div lang={lang} id='contact'>
            <section className='content-spacing'>
                <div>
                    <h1>{contactData["let's_collaborate_header"]}</h1>
                    <p>{contactData["let's_collaborate"]}</p>
                </div>
                
            </section>
            <section>
                <div id='contact-message'>
                    <div>
                        <form name="contact" method="POST" data-netlify="true">
                            <input type="hidden" name="subject" value="Contact from VelvetFlare WebPage" />
                            <h2>{contactData["send_us_a_message"]}</h2>
                            <label htmlFor="name">{contactData["what's_your_name"]}</label>
                            <input type="text" name="name" placeholder={contactData["what's_your_name_placeholder"]} />
                            <label htmlFor="email">{contactData["where_can_we_reach_you"]}</label>
                            <input type="email" name="email" placeholder={contactData["where_can_we_reach_you_placeholder"]} />
                            <label htmlFor="message">{contactData["how_can_we_help_you"]}</label>
                            <textarea name="message" placeholder={contactData["how_can_we_help_you_placeholder"]}></textarea>
                            
                            <button type="submit" className="button-container">
                                {contactData["say_hello"]}
                                <div className='button-overlay'></div>
                            </button>
                        </form>
                    </div>
                    <div>
                        <div>
                            <h2>{footerData.email_us_header}</h2>
                            <a href={`mailto:${footerData.email}`}>{footerData.email}</a><br />
                        </div>
                        <div>
                        <h2>{footerData.where_we_hang_out_header}</h2>
                        <a href="https://maps.app.goo.gl/mewuy1ddTVqYYMcu8" target="_blank" rel="noreferrer">
                            <span dangerouslySetInnerHTML={{ __html: footerData.address }} />
                        </a>
                        </div>
                        <div>
                            <h2>{footerData.find_us_on_header}</h2>
                            <div>
                                <a href={footerData.links.facebook} target="_blank" rel="noreferrer">Facebook</a>,{" "}
                                <a href={footerData.links.instagram} target="_blank" rel="noreferrer">Instagram</a>,{" "}
                                <a href={footerData.links.linkedin} target="_blank" rel="noreferrer">LinkedIn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;